@use './src/common/style/global' as *;

.explorer-item {
  display: flex;
  flex-direction: row;
  width: 100%;
  min-width: 0;
  cursor: pointer;

  &:not(:first-child) {
    margin-top: 12px;
  }
}

.explorer-item__inner {
  display: -webkit-flex;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  outline: none;
  padding: 0.5em;
  min-height: 3em;

  background-color: #fff;
  border: 1px solid #dadce0;
  border-radius: 0.5em;

  transition: background-color 200ms cubic-bezier(0.4, 0, 0.2, 1);

  &:hover {
    background-color: #f5f5f5;
  }
}

.explorer-item__img {
  display: inline-block;
  vertical-align: bottom;
  object-fit: contain;
  height: 3em;
  width: 3em;
}

.explorer-item__title {
  flex: 1;
  text-align: left;
  margin-left: 0.8em;

  @media screen and (max-width: $bp-mobile) {
    font-size: 12px;
  }
}
