@use './src/common/style/global' as *;

%tmp-button--nav {
  @include padding(4px, 27px, 4px, 27px);

  border: 1px solid $textColorSecondary;
  box-shadow: 1px 1px 4px $colorShadow;
  border-radius: 28px;

  &:hover {
    border: 1px solid #177840;
    color: $textColorSecondary;
    background: #177840;
  }

  &:active {
    border: 1px solid #177840;
    color: $textColorSecondary;
    background: #0F5C30;
  }

  @include more-than($bp-xxl-1440) {
    border: #{get-vw(1px, $bp-xxl-1440)} solid $textColorSecondary;
    box-shadow: #{get-vw(1px, $bp-xxl-1440)} #{get-vw(1px, $bp-xxl-1440)} #{get-vw(4px, $bp-xxl-1440)} $colorShadow;
    border-radius: #{get-vw(28px, $bp-xxl-1440)};

    &:hover {
      border: #{get-vw(1px, $bp-xxl-1440)} solid #177840;
    }

    &:active {
      border: #{get-vw(1px, $bp-xxl-1440)} solid #177840;
    }
  }

  @include less-than($bp-lg-979) {
    border: #{get-vw(1px, $bp-lg-979)} solid $textColorSecondary;
    box-shadow: #{get-vw(1px, $bp-lg-979)} #{get-vw(1px, $bp-lg-979)} #{get-vw(4px, $bp-lg-979)} $colorShadow;
    border-radius: #{get-vw(28px, $bp-lg-979)};

    &:hover {
      border: #{get-vw(1px, $bp-lg-979)} solid #177840;
    }

    &:active {
      border: #{get-vw(1px, $bp-lg-979)} solid #177840;
    }
  }

  @include less-than($bp-md-768) {
    border: #{get-vw(1px, $bp-md-768)} solid $textColorSecondary;
    box-shadow: #{get-vw(1px, $bp-md-768)} #{get-vw(1px, $bp-md-768)} #{get-vw(4px, $bp-md-768)} $colorShadow;
    border-radius: #{get-vw(28px, $bp-md-768)};

    &:hover {
      border: #{get-vw(1px, $bp-md-768)} solid #177840;
    }

    &:active {
      border: #{get-vw(1px, $bp-md-768)} solid #177840;
    }
  }
}

.navbar {
  width: 100%;
  height: 100%;

  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 28px;

    @include more-than($bp-xxl-1440) {
      padding: #{get-vw(28px, $bp-xxl-1440)};
    }

    @include less-than($bp-md-768) {
      padding: 22px 14px;
    }
  }

  &_bg {
    &_white {
      background-color: $white;
    }

    &_dark-blue {
      background-color: $subtitleBackground;
    }

    &_transparent {
      background-color: transparent;
    }
  }

  &__header {
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    align-items: flex-end;
  }

  &__title {
    margin-bottom: 12px;

    @media screen and (max-width: 420px) {
      font-size: 16px;
    }
  }

  &__link {
    //@extend %tmp-button--nav;
    font-family: $fontMain;
    font-weight: normal;
    text-align: center;
    color: $textColorSecondary;
    @include font-size(18px);
    @include padding(8px, 20px, 8px, 20px);
    @include margin(17px);


    &:hover {
      background: $textColorSecondary;
      color: $textColorPrimary;
    }

    &--special {
      @include margin(36px);
    }
  }

  &__cards {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &__card {
    width: calc(100% / 4 - 18px);
  }

  &__banner {
    width: 100%;
  }

  &__footer {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }

  &__union {
    position: absolute;
    top: 0;
    left: 2%;
    height: 8%;
    object-fit: contain;
  }

  &__logo {
    display: flex;
    width: 40%;
    align-self: center;
    object-fit: contain;
  }

  &__date {
    @include margin(20px);
  }

  &__social {
    width: 48%;
  }

  &__actions {
    display: flex;
    flex-direction: column;

    &-item {
      & + & {
        margin-top: 0.4em;
      }
    }
  }
}

.nav-links {
  width: 100%;

  &__item {
    display: flex;
    flex-direction: column;

    & + & {
      margin-top: 12px;

      @media screen and (max-width: 375px) {
        margin-top: 6px;
      }
    }
  }
}

.social-list {
  display: flex;
  justify-content: space-between;

  &__item {
    border-radius: 12%;
    overflow: hidden;

    & + & {
      margin-left: 4%;
    }
  }

  &__link {

  }

  &__link-img {
    object-position: center;
    object-fit: contain;
    width: 100%;
  }
}

.navbar-card {
  @media screen and (max-width: 420px) {
    margin-top: 6px;
  }

  &__link {
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  &__img {
    width: 100%;
    object-fit: cover;
    object-position: top center;

    background-color: #E6F2FB;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    transition: transform 300ms;

    &:hover {
      transform: scale(1.08);
    }

  }

  &__label {
    margin-top: 0.6em;

    @media screen and (max-width: 340px) {
      font-size: 12px;
    }
  }
}

.navbar-banner {
  position: relative;
  width: 100%;
  margin-top: 3vh;

  @media screen and (max-height: 620px) {
    margin-top: 0;
  }

  &__subtitle {
    margin-top: 0.5em;
  }

  &__description {

  }

  &__btn {
    margin: 1em 0;
  }

  &__bg {
    object-position: bottom right;
    width: 52%;
    position: absolute;
    bottom: 0;
    right: 0;
  }
}
