@use './src/common/style/global' as *;

.img-button {
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  padding: 0.7em;
  text-decoration: none;
  transition: background-color 200ms cubic-bezier(0.4, 0, 0.2, 1);
  background-color: rgba(216, 217, 219, .5);

  @media screen and (max-width: $bp-tablet) {
    padding: 0.5em;
  }

  &:hover {
    background-color: rgba(216, 217, 219, 1);
  }

  &__img {
    object-fit: contain;
    object-position: center;
    display: block;
    min-height: 14px;
    min-width: 14px;
    height: 100%;
    width: 100%;

    @media screen and (max-width: $bp-mobile) {
      height: 80%;
      width: 80%;
    }

    &_mirrorY {
      transform: rotateY(180deg);
    }
  }
}
