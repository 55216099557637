$light-gray: #eee;
$white: #FFFFFF;
$gray: #f5f5f5;
$green: #219653;
$blue: #031435;
$gray-blue: #0d1d47;
$black: #000;
$dark-blue: #111E44;

// Colors
$textColorPrimary: #000000;
$textColorSecondary: #FFFFFF;
$textColorAccent: #373737;

$colorPrimary: #031435;
$colorSecondary: #219653;
$colorSecondaryDark: #1d854a;
$colorAccent: #219653;

$windowBackground: #F5F5F5;
$popupBackground: #FFFFFF;
$subtitleBackground: #001437;
$colorShadow: rgba(0, 0, 0, 0.25);

$label-bg-gray: #e8e8e8;
$label-bg-gray-hover: #d7d7d7;
$label-text-gray: #8b8b8b;
$placeholder-text-gray: #c4c4c4;
$timestamp-text-gray: #acacac;
$timestamp-text-gray-hover: #757575;

//$fontMain: 'Helvetica', Arial, sans-serif;
$fontMain: 'Roboto', Arial, sans-serif;


$header-height: 58px;
$navbar-width: 283px;

$z-index-nav: 5;
$z-index-header: 10;
$z-index-drop-down-menu: 20;

// Sass Screen Variables
$bp-xxl-1440: 1440px;
$bp-xl-1170: 1170px;
$bp-lg-979: 979px;
$bp-md-768: 768px;
$bp-sm-576: 576px;

$bp-mobile: 486px;
$bp-tablet: 924px;
$bp-laptop: 1024px;
$bp-large: 1440px;
