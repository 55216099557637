@use './src/common/style/global' as *;

.navBar {
  width: 100%;
  height: 100%;
  padding: 12px 18px;
  border-bottom: 1px solid rgba(196, 196, 196, 1);

  @media screen and (max-width: $bp-tablet) {
    padding: 8px;
  }
}

.navBar__inner {
  width: 100%;
  display: flex;
  align-items: center;

  @media screen and (max-width: $bp-tablet) {
    display: grid;
    align-items: center;
    grid-template-columns: 2fr 3fr 1fr;
    grid-template-rows: repeat(2, 1fr);
  }
}

.navBar__nav-actions {
  display: flex;
  justify-content: flex-start;
  flex-shrink: 0;

  @media screen and (max-width: $bp-tablet) {
    grid-area: 1 / 1 / 2 / 3;
  }
}

.navBar__nav-action {
  & + & {
    margin-left: 0.5em;
  }
}

.navBar__title {
  flex: 2 1;
  line-height: 100%;
  overflow: hidden;

  @media screen and (max-width: $bp-tablet) {
    grid-area: 2 / 1 / 3 / 4;
    font-size: 16px;
    padding-bottom: 8px;
  }
}

.navBar__share-actions {
  display: flex;
  justify-content: flex-end;
  flex-shrink: 0;

  @media screen and (max-width: $bp-tablet) {
    grid-area: 1 / 3 / 2 / 4;
  }
}
