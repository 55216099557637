@use './src/common/style/global' as *;

.docs-viewer {
  width: 100%;
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
}

.docs-viewer__inner {
  width: 100%;
  border: 0;
  flex: 1 0 auto;
  position: relative;
}

.docs-viewer__loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: -1;
}
