@use './src/common/style/global' as *;

.button {
  @extend %text;
  align-items: center;
  background-color: $gray;
  border-radius: 1em;
  cursor: pointer;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  padding: 0.8em;
  text-decoration: none;
  //transition: background-color 200ms cubic-bezier(0.4, 0, 0.2, 1);

  &_full {
    width: 100%;
  }

  &_type {
    &_main {
      background-color: $white;
      border: 1px solid $black;
      border-radius: 1.6em;
      color: $black;
      padding: 0.8em 1.5em;

      &:hover {
        color: #FFFFFF;
        background: #219653;
        border-color: #219653;
      }
    }

    &_table {
      background-color: $white;
      border: 1px solid $black;
      border-radius: 1.2em;
      color: $black;
      padding: 0.5em 1em;
      font-size: 12px;
    }

    &_tabs {
      background-color: $white;
      border: 1px solid $black;
      border-radius: 1.2em;
      color: $black;
      padding: 0.5em 1em;
      font-size: 14px;
      flex-shrink: 0;

      @media screen and (max-width: 420px) {
        font-size: 12px;
      }
    }

    &_menu {
      border-radius: 6px;
      border-width: 0;
      overflow: hidden;
      padding: 14px 24px;
      background: #f5f5f5;
      box-shadow: none;
      color: $gray-blue;
      text-align: left;

      @media screen and (max-width: $bp-mobile) {
        padding: 0.8em;
      }

      &:hover {
        background: #E1E1E1;
        color: $gray-blue;
      }

      @include less-than(420px) {
        font-size: 14px;
      }

      @include less-than(340px) {
        font-size: 12px;
      }
    }

    &_submit {
      height: fit-content;
      font-family: $fontMain;
      font-weight: normal;
      color: #FFFFFF;
      background: #219653;
      @include font-size(14px);

      @include less-than($bp-md-768) {
        font-size: 12px;
      }
    }

    &_navbar {
      border-radius: 4px;
      background-color: rgba(216, 217, 219, .5);

      &:hover {
        background-color: rgba(216, 217, 219, 1);
      }
    }
  }

  &_burger {
    display: block;
    background-color: $white;
    color: $dark-blue;
    font-size: 16px;
    font-weight: bold;
    border: 1px solid #B3B3B3;
    padding: 0.4em 1em;
    border-radius: 8px;
    overflow: hidden;

    &:hover {
      background-color: $dark-blue;
      border-color: $dark-blue;
      color: white;
    }

    &.active, &.is-nav-selected {
      background-color: $dark-blue;
      border-color: $dark-blue;
      color: $white;

      &:hover {
        background-color: #{lighter($dark-blue, .65)};
        border-color: #{lighter($dark-blue, .65)};
      }
    }
  }

  &__img {
    display: block;
    height: 18px;
    object-position: center;
    width: 18px;
  }

  &__txt {
    color: inherit;
    margin-left: 0.5em;
  }

  &.active {
    background-color: $green;
    border: 1px solid $green;
    border-radius: 1.6em;
    color: $white;

    &:hover {
      background-color: $colorSecondaryDark;
      border: 1px solid $colorSecondaryDark;
    }
  }
}
