@use './src/common/style/global' as *;

.header {
  background: $white;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
  height: $header-height;
  width: 100%;
  padding: 10px 20px 10px 20px;

  @media screen and (min-width: 1442px) {
    $top: 10px;
    $right: 20px;
    $bottom: 10px;
    $left: 20px;

    padding-top: #{get-vw($top, 1442px)};
    padding-right: #{get-vw($right, 1442px)};
    padding-bottom: #{get-vw($bottom, 1442px)};
    padding-left: #{get-vw($left, 1442px)};
  }

  @media screen and (max-width: 768px) {
    padding: 12px;
  }

  &__inner {
    align-items: center;
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
    justify-content: space-between;
    overflow: hidden;
  }

  &__logo {
    align-items: center;
    display: flex;
    justify-content: flex-start;

    @include less-than(465px) {
      justify-content: center;
    }
  }

  &__action {
    align-items: center;
    display: flex;
    justify-content: flex-end;
  }
}

.header__action-item {
  outline: none;

  & + & {
    margin-left: 12px;
    border-left: 1px solid #C4C4C4;
    padding-left: 12px;
  }

  &_call {
    @media screen and (max-width: 576px) {
      display: none;
    }
  }
}

.action-header {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &__item {
    &_link {

    }

    &_tel {
      margin-right: 35px;
    }

    &_menu {
      display: none;
    }
  }
}

.logo-header {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 100%;

  &__img {
    display: block;
    object-fit: contain;
    height: 32px;
  }

  &__label {
    display: block;
    margin-left: 12px;

    @media screen and (max-width: 412px) {
      font-size: 11px;
    }
  }
}

.dropdown-menu {
  border: 0;
  color: #f1f1f1;
  display: inline-block;
  font-size: 16px;

  &__content {
    background-color: #f1f1f1;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    display: none;
    min-width: 160px;
    position: fixed;
    right: 0;
    top: 0;
    transform: translateY($header-height);
    z-index: $z-index-drop-down-menu;

    &-active {
      display: block;
    }
  }

  &__link {
    color: #000;
    display: block;
    padding: 12px 16px;
    text-decoration: none;

    &:hover {
      background-color: #ddd;
    }
  }

  &__btn {

  }
}

.hamburger {
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 30px;
  justify-content: center;
  width: 30px;
  position: relative;
  z-index: 2;

  &__line {
    align-items: center;
    background: $black;
    display: flex;
    flex: none;
    height: 2px;
    justify-content: center;
    position: relative;
    transition: all 0.4s ease;
    width: 100%;

    &::before,
    &::after {
      background: inherit;
      content: '';
      height: 2px;
      position: absolute;
      top: -7px;
      width: 100%;
      z-index: 1;
    }

    &::after {
      top: 7px;
    }
  }
}

.doter {
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 30px;
  justify-content: center;
  width: 30px;


  &__dot {
    align-items: center;
    background: $black;
    border-radius: 50%;
    display: flex;
    flex: none;
    height: 6px;
    justify-content: center;
    position: relative;
    transition: all 0.4s ease;
    width: 6px;

    &::before,
    &::after {
      background: inherit;
      border-radius: 50%;
      content: '';
      height: 6px;
      position: absolute;
      top: -10px;
      width: 6px;
      z-index: 1;
    }

    &::after {
      top: 10px;
    }
  }
}

.call-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: $black;
  padding: 12px;
  border-radius: 24px;

  &:hover {
    text-decoration: underline;
  }

  &__img {
    object-fit: contain;
    object-position: center;
    height: 1em;
    transition: transform .3s;

    &:hover {
      transform: scale(1.1);
    }
  }

  &__label {
    margin-left: 0.5em;
    color: inherit;
  }
}

@media screen and (max-width: 1024px) {
  .header {
  }
}

@media screen and (max-width: 780px) {

  .action-header {
    &__item {
      &_link {
        display: none;
      }

      &_tel {
        display: none;
      }

      &_menu {
        display: block;
      }
    }
  }
}

