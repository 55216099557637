@use './src/common/style/global' as *;

.Modal {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.42);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  z-index: -1;

  &_active {
    opacity: 1;
    transition: opacity 285ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    z-index: 1;
  }
}

.Modal__inner {
  width: 40vw;
  min-height: 30vh;
  background-color: white;
  border-radius: 1em;
  padding: 2em;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 11px 15px -7px, rgba(0, 0, 0, 0.14) 0px 24px 38px 3px, rgba(0, 0, 0, 0.12) 0px 9px 46px 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (max-width: $bp-lg-979) {
    width: 60vw;
  }

  @media screen and (max-width: $bp-sm-576) {
    width: 90vw;
    padding: 1em;
  }
}

.Modal__content {
  flex-grow: 1;
}

.Modal__btn-cancel {
  width: 58%;
  margin: 24px auto 0;
  flex: 0 0;
}
