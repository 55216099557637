@use './src/common/style/global' as *;

.drawer {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  pointer-events: none;
  z-index: #{$z-index-header + 1};

  &__overlay {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;

    background-color: black;

    visibility: hidden;
    opacity: 0;
    transition: .5s;

    z-index: -1;
  }

  &__sidebar {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 512px;
    @media screen and (min-width: 1442px) {
      width: #{get-vw(512px, 1442px)};
    }

    pointer-events: auto;
    transform: translateX(100%);
    transition: transform .3s;
    will-change: transform;
    contain: paint;

    @include less-than($bp-md-768) {
      width: 100vw;
    }

    &_pos {
      &_left {
        left: 0;
      }

      &_right {
        left: auto;
        right: 0;
      }
    }

    background-color: #C4C4C4;
  }

  &.is_active {
    pointer-events: auto;

    .drawer__overlay {
      visibility: visible;
      opacity: 0.6;
    }

    .drawer__sidebar {
      transform: none;
    }
  }
}

.burger {
  display: none;
  flex-direction: column;
  justify-content: center;
  border: 0;
  background: transparent;
  border-radius: 0;
  height: 70px;
  width: 30px;
  cursor: pointer;
  outline: none;

  position: relative;
  z-index: #{$z-index-header + 2};

  &__item {
    outline: none;
    display: block;
    width: 100%;
    height: 2px;
    background: $black;
    transition: transform .3s;

    & + & {
      margin-top: 5px;
    }
  }
}

.header__action-item.is_active {
  .button_burger {
    display: none;
  }

  .burger {
    display: flex;
  }

  .burger__item {
    display: flex;

    &:nth-of-type(1) {
      transform: translate3d(0, 7px, 0) rotate(45deg);
    }

    &:nth-of-type(2) {
      opacity: 0;
    }

    &:nth-of-type(3) {
      transform: translate3d(0, -7px, 0) rotate(-45deg);
    }
  }
}
