@use './src/common/style/global' as *;

.explorer {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1440px;
  padding: 24px 18px;

  @media screen and (max-width: $bp-tablet) {
    padding: 12px 8px;
  }
}
