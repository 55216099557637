@charset "UTF-8";
@use 'foundation';

.app {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
}

.app__header {
  flex: 0 0 58px;
  width: 100%;
  background-color: #ecba40;
}

.app__navbar {
  flex: 0 0 58px;
  width: 100%;
}

.app__content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
}

$p-side: 20px;
$maxWidthContainer: 1200px;
$maxWidthContainer: $maxWidthContainer + 2 * $p-side;
.container {
  max-width: $maxWidthContainer;
  margin: 0 auto;
  padding: 0 $p-side;

  @media screen and (min-width: 1440px) {
    max-width: #{get-vw($maxWidthContainer, 1440px)};
    padding: 0 #{get-vw($p-side, 1440px)};
  }

  @media screen and (max-width: 768px) {
    padding: 0 12px;
  }
}
